<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Forum
        </div>
        <div class="subtitle-page wother speWidth">
          <div>
           <span>{{title}}</span>
          </div>
          <div class="other">
            <div class="btn-green small radius-small reverse" @click="goBack">Go Back</div>
            <div class="btn-green small radius-small" @click="toggleEdit">Add Thread</div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-forum" v-if="newThreadForm">
      <input class="v-select" v-model="newThreadTitle" placeholder="Thread Title" />
      <wysiwyg class="radius" style="margin-top:2vh;" v-model="newThreadText" />
      <div class="cont-submit-forum-btn">
        <div class="btn-green radius reverse" @click="toggleEdit">Cancel</div>
        <div class="btn-green radius" @click="submit"> Submit</div>
      </div>
    </div>
    <div id="listcategories" class="container-threads">
        <thread v-for="thread in threads" :key="thread._id" :forumThread="thread" />
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import Thread from '@/components/UniversalComponents/Forum/thread.vue';

export default {
  name: 'ForumThreads',
  components: {
    Thread,
  },
  data() {
    return {
      threads: [],
      title: '',
      newThreadTitle: '',
      newThreadText: '',
      newThreadForm: false,
      lastposts: [],
    };
  },
  mounted() {
    this.$api.get(`forum/category/${this.$route.params.id}`).then((res) => {
      this.threads = res.data.threads;
      this.title = res.data.title;
      const ps = new PerfectScrollbar('#listcategories', {
        wheelPropagation: false,
        wheelSpeed: 1,
        suppressScrollX: true,
      });
      ps.update();
    });
  },
  methods: {
    toggleEdit() {
      this.newThreadTitle = '';
      this.newThreadForm = !this.newThreadForm;
    },
    submit() {
      const updateBody = { category: this.$route.params.id, title: this.newThreadTitle };
      if (this.newThreadText) {
        updateBody.text = this.newThreadText;
      }
      this.$api.post('forum/thread', updateBody).then((res) => {
        this.$router.push(`/forum/thread/${res.data._id}`);
      });
    },
    goBack() {
      this.$router.push('/forum/');
    },
    getLastPost() {
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .subtitle-page{
    color: $text_color;
    margin-top: 5vh;
    margin-bottom: 1vh;
    font-weight: 600;
    &.speWidth{
      width: calc(100% - 1vh);
    }
    span{
      color: $logo_color;
      font-weight: 400;
    }
  }

</style>
