<template>
<div class="elem-thread box-shadow radius" @click="goTo">
    <div class="inner-thread">
      <!-- {{forumThread}} -->
      <div class="head-thread">
        <div class="title-thread">
            {{forumThread.title}}
          </div>
        <div class="cont-info">
          <div class="name-user-thread">
            {{forumThread.author.name.firstName}} {{forumThread.author.name.lastName}}<br>
          </div>
          <div class="separ-thread"> - </div>
          <div class="name-date-thread">
            {{forumThread.createdAt | moment('dddd, MMMM Do YYYY')}}
          </div>
        </div>
      </div>
      <div class="foot-thread">
        <div class="cont-count">
          <div class="elem-count radius-small">
            <div class="info-data">
              {{forumThread.posts.length}}
            </div>
            <div class="info-title">
              Posts
            </div>
          </div>
        </div>
        <div class="ppl-thread">
          <div
            class="pict-poster box-shadow"
            v-for="pic in forumThread.recentReplys" :key="pic._id"
            :style="`background-image: url(${pic.author.profilePicture})`"
          >
            <!-- {{pic.author.profilePicture}} -->
          </div>
          <div class="pict-poster box-shadow" v-if="forumThread.posts.length > 3">
            +{{(forumThread.posts.length) - 4}}
          </div>
        </div>
      </div>
    </div>
    <div class="last-post box-shadow" v-if="lastPosts">
      <div class="sub-title">
        Last message posted
      </div>
      <div class="head">
        <div
          class="picto"
          v-if="lastPosts.author.profilePicture"
          :style="`background-image:url(${lastPosts.author.profilePicture})`"
        ></div>
        <div class="picto" v-else></div>
        <div class="info">
          <div class="top-info">
            <p>
              {{lastPosts.author.name.firstName}} {{lastPosts.author.name.lastName}}
            </p>
          </div>
          <div class="bot-info">
            <div class="">
              {{lastPosts.createdAt | moment('dddd, MMMM Do YYYY')}}
            </div>
            <div class="">
              {{lastPosts.createdAt | moment('HH:mm')}}
            </div>
          </div>
        </div>
      </div>
      <div class="content-forum-post radius">
        <div class="cont-last-post" v-html="lastPosts.body"></div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'ThreadDisplay',
  props: ['forumThread'],
  computed: {
    lastPosts() {
      return this.forumThread.lastPost;
    },
  },
  data() {
    return {
      posts: [],
    };
  },
  mounted() {

  },
  methods: {
    goTo() {
      this.$router.push(`/forum/thread/${this.forumThread._id}`);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .elem-thread{
    color: #ffffff;
    cursor: pointer;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    &:hover{
      .last-post{
        transform: translateX(0);
      }
    }
    .inner-thread{
      width: calc(100% - 4vh);
      padding: 2vh;
      display: flex;
      flex-direction: column;
      .head-thread{
        width: 100%;
        display: flex;
        flex-direction: column;
        .title-thread{
          margin-bottom: 0.5vh;
        }
        .cont-info{
          display:flex;
          flex-direction: row;
          font-size: 1.2vh;
          .separ-thread{
            margin-left: 1vh;
            margin-right: 1vh;
          }
        }
      }
      .foot-thread{
        margin-top: 8vh;
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        .cont-count{
          .elem-count{
            background-color: #ffffff;
            color: $logo_color;
          }
        }
        .ppl-thread{
          display:flex;
          flex-direction: row;
          .pict-poster{
            height: 5vh;
            width: 5vh;
            background-color: $red_color;
            border-radius: 50%;
            background-size: cover;
            background-position: 50% 50%;
            margin-right: -1.5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: $logo_color;
            border: 0.1vh solid #ffffff;
            font-family: "MontserratBold";
            line-height: 1;
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
    .last-post{
      position: absolute;
      top: 0;
      top: -10vh;
      transform: translateX(110%);
      transition: all .4s;
      right: 0;
      background-color: #ffffff;
      height: calc(100% - 8vh);
      padding: 2vh;
      width: 30vh;
      padding-top: 16vh;
      padding-left: 7vh;
      color: $text_color;
      border-top-left-radius: 35vh;
      .sub-title{
        font-weight: 600;
        color: $logo_color;
        margin-bottom: 1vh;
      }
      .head{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .picto{
          background-color: $logo_color;
          height: 4.5vh;
          width: 4.5vh;
          border: 0.1vh solid $logo_color;
          background-size: cover;
          background-position: 50% 50%;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1.5vh;
        }
        .info{
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-wrap: wrap;
          font-size: 1.6vh;
          .top-info{
            p{
              white-space: nowrap;
              width: 23.5vh;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .bot-info{
            margin-top: 0.5vh;
            font-size: 1.2vh;
            display: flex;
            flex-direction: row;
            div{
              margin-right: 1vh;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }
      .content-forum-post{
        margin-top: 1.5vh;
        max-height: 7vh;
        overflow: hidden;
        padding: 1vh;
        font-size: 1.4vh;
        background-color: $new_bg_grey;
      }
    }
  }
</style>
